<template>
	<span>
		<button class="mg-r-5 el-button el-button--primary is-plain" :class="{active:interval}" @mousedown="start(),stopFunc = true" @mouseup="stop" @touchstart="start" @touchend="stop" @touchcancel="stop" @mouseleave="stopFunc ? stop() : ''">Random</button><b v-if="showNumber"> {{count}} </b>
	</span>
</template>
<script scoped>
	export default{
		props:{
			showNumber:{
				type:Boolean,
				default:false
			},
			min: {
	      	type: Number,
	      	default: 0
	    	},
	    	max: {
		      	type: Number,
		      	default: 9
	    	},
		},
		data(){
		  	return {
		  		stopFunc:false,
		      interval:false,
		      count:1,
		    }
		},methods:{
		  	start(){
		  		
		    	if(!this.interval){
		        let min = Number(this.min);
		        let max = Number(this.max);
		        if (min > max) {
		          [min, max] = [max, min];
		        }
		        this.min = min;
		        this.max = max;
		        
		      	/* this.interval = setInterval(() => this.count++, 30) */
		        
				this.interval = setInterval(() => {this.count = Math.floor(Math.random() * (this.max - this.min + 1) + this.min); this.$emit('runningNumber',this.count)}, 30)
				
		      }
		    },
		    stop(){
		    	this.stopFunc = false
				clearInterval(this.interval)
				this.interval = false
				this.$emit('stopNumber',this.count)
		    }
	  	}
	}
</script>
<style scoped>
	.active{
	  color:green
	}
	button{
	  user-select:none
	}
</style>