<template>
    <div>
      <div class="app-main pd-y-2">
        <el-row type="flex" justify="center">
          <el-col :span="22">
            <div class="mg-b-5 is-flex js-between"> 
              <div>
                <span class="mg-less font-24">{{room.current}} </span>
                <span v-if="typeof user.name !=='undefined' && user.name !== null ">
                 <el-divider direction="vertical"></el-divider>{{user.name}} 
                  <sup>
                    [<span v-if="typeof accounts !=='undefined' &&  accounts !== ''">Branding Rate: <span><div  class="d-inline">
                        <div v-if="renderAnimate.branding" class="animated bounce">
                          <span  class="color-primary">
                            {{accounts.branding |comma}}
                          </span> 
                        </div>
                        <span v-else class="color-primary">
                            <i class="el-icon-loading"></i>
                        </span> 
                      </div> |</span>
                    </span>
                    <span v-if="typeof accounts !=='undefined' &&  accounts !== ''">
                      R&D Rate: <div  class="d-inline">
                        <div v-if="renderAnimate.rd" class="animated bounce">
                          <span  class="color-primary">
                            {{accounts.rd |comma}}
                          </span> 
                        </div>
                         <span v-else class="color-primary">
                            <i class="el-icon-loading"></i>
                        </span> 
                      </div>
                    </span>]
                  </sup>
                </span>
                 
              </div>
              <div class="text-right">
                <span>
                  <span v-if="user.userLevel !== 80 && typeof accounts !=='undefined' &&  accounts !== '' &&  accounts !== null">Payment term:
                    <div style="min-width:70px;" class="d-inline">
                      <div v-if="renderAnimate.paymentTerm" class="animated bounce">
                        <div v-if="accounts.paymentTermName" class="color-primary">
                          {{accounts.paymentTermName}} 
                        </div>
                        <div v-else class="text-center"><i class="el-icon-loading"></i></div>
                      </div>
                      <div v-else class="text-center">
                        <i class="el-icon-loading"></i>
                      </div>
                    </div> |
                  </span> Round {{room.currentRound}}
                  <sup v-if="typeof user.name !=='undefined' &&  user.name !== null && fxRate.length > 0">
                    [<el-tooltip class="item" effect="dark" :content="'FxRate ของรอบนี้คือ ฿'+fxRate[room.currentRound-1].currentRate+'/USD เนื่องจากคุณได้ซื้อ Forward Contract FxRate ของคุณคือ '+calFxRate()" placement="top" :disabled="room.currentRound === 1 || typeof groupAllData.accounts[room.currentRound-2] === 'undefined' || groupAllData.accounts[room.currentRound-2].fw === false">
                      <span class="primary-color-blue">{{typeof user.name !=='undefined' &&  user.name !== null  ?'  FxRate '+calFxRate():''}}
                    </span>  </el-tooltip>]
                  </sup>
                </span>
                  <el-dropdown v-if="user.userLevel == 80 || (user.userLevel == 0 && room.mode == 'single')">
                    <span class="el-dropdown-link" style="cursor:pointer">
                     <i class="el-icon-more fa-rotate-90"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <div @click="modalConfirm = true">
                          ยกเลิกเกมส์นี้
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
              </div>
            </div>
            <div >
              <slot></slot>
            </div>
            <div v-if="typeof user.userLevel != 'undefined' && user.userLevel == 80" class="mg-t-3">
              <el-divider content-position="left"><i class="el-icon-user-solid"></i> Players</el-divider>
              <el-tabs v-model="activeTab" type="border-card">
                <el-tab-pane :label="data.name" :name="data._id" v-for="(data,index) in room.groups" :key="index">
                  <sup>
                    [<span v-if="typeof accounts !=='undefined' && accounts !== ''">Payment Term: <div class="d-inline">
                        <div v-if="renderAnimate.paymentTerm" class="animated bounce">
                          <span v-if="accounts.paymentTermName" class="color-primary">
                            {{accounts.paymentTermName}}
                          </span>
                          <span v-else><i class="el-icon-loading"></i></span>
                        </div>
                        <div v-else class="color-primary">
                            <i class="el-icon-loading"></i>
                        </div> 
                      </div> | 
                      Branding Rate: <span><div class="d-inline">
                        <div v-if="renderAnimate.branding" class="animated bounce">
                          <span class="color-primary">
                            {{accounts.branding |comma}}
                          </span> 
                        </div>
                        <div v-else class="color-primary">
                            <i class="el-icon-loading"></i>
                        </div> 
                      </div> |</span>
                    </span>
                    <span v-if="typeof accounts !=='undefined' && accounts !== ''">
                      R&D Rate: <div class="d-inline">
                        <div v-if="renderAnimate.rd" class="animated bounce">
                          <span class="color-primary">
                            {{accounts.rd |comma}}
                          </span> 
                        </div>
                         <div v-else class="color-primary">
                            <i class="el-icon-loading"></i>
                        </div> 
                      </div>
                    </span>]
                  </sup>
                  <el-tabs v-model="activeSubTab">
                    <el-tab-pane label="Accounting" name="accounting">
                      <AccountList :accounting="accounting" :calBalanceLast="calBalanceLast" :eventCard="eventCard" />
                    </el-tab-pane>
                    <el-tab-pane label="Inventory" name="inventory">
                        <InventoryBalance :userId="data._id" />
                    </el-tab-pane>
                  </el-tabs>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-dialog :visible.sync="modalConfirm" width="30%">
          <div class="color-danger text-center">
            <h3 class="text-center">ยืนยันการออกจากเกมส์ ?</h3>
            <p>หากยกเลิกเกมส์ตอนนี้ ผู้เล่นทุกคนจะถูกบังคับออกจากห้องทันที</p>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="modalConfirm = false">Cancel</el-button>
            <el-button type="primary" @click="destroy()">Confirm</el-button>
          </span>
      </el-dialog>
    </div>
</template>
<script>
import AccountList from '@/components/accounting/AccountList';
import InventoryBalance from '@/components/inventory/InventoryBalance';
import {HTTP} from'@/service/axios'
export default {
    components:{
        AccountList,
        InventoryBalance
    },
    data(){
      return{
        activeTab:'',
        modalConfirm:false,
        payment:'',
        eventCard:null,
        accounting:[],
        activeSubTab:'accounting'
      }
    },
    watch:{
      activeTab(){
        this.fetchGroupAllData(this.activeTab)
      }
    },
    computed: {
      user(){
        return this.$store.state.user
      },
      room(){
        return this.$store.state.room
      },
      fxRate(){
        return this.$store.state.fxRate
      },
      groupAllData(){
        return this.$store.state.groupAllData
      },
      accounts(){
        if(this.groupAllData === null){ return '' }
        let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
        if(filter.length > 0){
            return filter[0]
        }else{
            return ''
        }
      },
      renderAnimate(){
        return this.$store.state.renderAnimate
      }
    },
    mounted(){
      if(typeof this.user.userLevel != 'undefined' && this.user.userLevel == 80){
        this.activeTab = this.room.groups[0]._id
      }
      if(typeof this.user.userLevel == 'undefined'){
        this.fetchGroupAllData(this.user._id)
      }
    },
    methods: {
        fetchGroupAllData(id){
            let obj ={
                roomId:this.room._id,
                groupId:id,
            }
            return this.$store.dispatch('FETCH_GROUP_ALL_DATA',obj).then(()=>{
                this.fetchAllData()
            })
        },
        async fetchAllData(){
            let accountArray = [{
                id:1,
                name: 'เงินคงเหลือต้นงวด',
                statusMoney:'',
                round1:0,
                round2:0,
                round3:0,
                round4:0,
                round5:0,
                round6:0
            }]
            let income = this.setDataAccount(this.groupAllData.accounts,'income','รายได้','+',2)
            accountArray.push(income)
            let tradeAccountReceivables = this.setDataAccount(this.groupAllData.accounts,'tradeAccountReceivables','รายได้จากลูกหนี้การค้า','+',3)
            accountArray.push(tradeAccountReceivables)
            let credit = this.setDataAccount(this.groupAllData.accounts,'credit','สินเชื่อ','+',4)
            accountArray.push(credit)
            let principle = this.setDataAccount(this.groupAllData.accounts,'principle','คืนเงินต้น','-',5)
            accountArray.push(principle)

            let balanceCredit = []
            for (let x = 0; x < 6; x++) {
                let credit = 0
                let principle = 0
                for (let y = 0; y <= x; y++) {
                    credit = credit + this.groupAllData.accounts[y].credit
                    principle = principle + this.groupAllData.accounts[y].principle  
                }   
      
                let sum = credit - principle
                balanceCredit.push(sum) 
            }
            
         
             let creditBalance = {
                id:6,
                name: 'สินเชื่อคงเหลือ',
                statusMoney:'',
                round1:balanceCredit[0],
                round2:balanceCredit[1],
                round3:balanceCredit[2],
                round4:balanceCredit[3],
                round5:balanceCredit[4],
                round6:balanceCredit[5]
            }
           
            accountArray.push(creditBalance)

            let interest = this.setDataAccount(this.groupAllData.accounts,'interest','ดอกเบี้ย (5% ของสินเชื่อ)','-',7)
            accountArray.push(interest)
            let cost = this.setDataAccount(this.groupAllData.accounts,'cost','ต้นทุนผลิต','-',8)
            accountArray.push(cost)
            let insurance = this.setDataAccount(this.groupAllData.accounts,'insurance','เบี้ยประกัน (1% มูลค่าส่งออก)','-',9)
            accountArray.push(insurance)
            let economicInsights = this.setDataAccount(this.groupAllData.accounts,'economicInsights','Economic Insights','-',10)
            accountArray.push(economicInsights)
            let branding = this.setDataAccount(this.groupAllData.accounts,'brandingAmount','Branding','-',11)
            accountArray.push(branding)
            let rd = this.setDataAccount(this.groupAllData.accounts,'rdAmount','R&D','-',12)
            accountArray.push(rd)
            let warehouse = this.setDataAccount(this.groupAllData.accounts,'warehouseAmount','Warehouse','-',13)
            accountArray.push(warehouse)
            let busniessEvenCard = this.setDataAccount(this.groupAllData.accounts,'busniessEvenCard','Business Event','-',14)
            accountArray.push(busniessEvenCard)
            let cardAction = this.setDataAccount(this.groupAllData.accounts,'becName','Business Event Card Action','-',15)
            this.eventCard = cardAction
            this.accounting = accountArray

            let balance = {
                id:1,
                name: 'เงินคงเหลือต้นงวด',
                statusMoney:'',
                round1:0,
                round2:(this.accounting.length)
                        ?this.calBalanceLast(this.accounting,'round1',0)
                        :0,
                round3:(this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0))
                        :0,
                round4:(this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0)))
                        :0,
                round5:(this.accounting.length > 0)?
                        this.calBalanceLast(this.accounting,'round4',this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0))))
                        :0,
                round6:
                        (this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round5',this.calBalanceLast(this.accounting,'round4',this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0)))))
                        :0
            }
            this.accounting[0]=balance
      },
      setDataAccount(data,property,name,status,id){
          let inventoryRound = {
            id:id,
            name: name,
            statusMoney:status,
            round1:data[0][property],
            round2:data[1][property],
            round3:data[2][property],
            round4:data[3][property],
            round5:data[4][property],
            round6:data[5][property]
          }

          return inventoryRound
        },
      calBalanceLast(data,round,begin){
          let increase =  0 
          let decrease = 0

          data.map((item)=>{
              if(item.id === 14){
                  decrease = decrease + sumEventMoney(item[round])
              }else if(item.statusMoney === '-' && item.id !== 6){
                  decrease = decrease + item[round]
              }else if(item.id !== 6){
                  let beginMoney = (item.id === 1)?begin:0
                  increase = increase + item[round] + beginMoney
              }
          })
      
          return increase - decrease

          function sumEventMoney(money){
                let sum = 0
                money.map((item)=>{
                    let amount = (typeof item.amount !== 'undefined')?item.amount:0
                    sum = sum + amount
                })
                return sum
            }
      },
      async destroy(){
        this.room.current = 'Destroy Game'
        await this.$store.dispatch('updateCurrent',this.room)
        if(this.user != null && this.user.userLevel == 80 ){
          await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
        }
        await HTTP.delete('/end/'+this.room._id).then(res=>{

          if(res.data.success){
            this.confirmModal = false
            if(this.room.mode == 'single' && this.user.userLevel == 0){
                    this.$router.push('/select-mode').catch(err => {})
            }else if(this.user.userLevel == 80){
                    this.$router.push('/create-room').catch(err => {})
            }else{
              this.$router.push('/join').catch(err => {})
            }
            this.$notify.error({
              title: 'Room #'+this.room.roomNumber+' was destroyed.',
              message: 'ขออภัย เกมส์ #'+this.room.roomNumber+' ได้ถูกยกเลิกแล้ว',
              type: 'error'
            });
          }
        })
      },
      calFxRate(){
        let index = (this.room.currentRound > 1)?this.room.currentRound-2:0

        if(this.groupAllData && this.groupAllData.accounts[index]){
          if((typeof this.groupAllData.accounts[index].fw !== 'undefined' && this.groupAllData.accounts[index].fw === false) || this.room.currentRound === 1){
            return '฿'+this.fxRate[this.room.currentRound-1].currentRate+'/USD'
          }else{
            return '฿'+this.fxRate[index].fwdRate+'/USD'
          }
        }
      }
    },
}
</script>