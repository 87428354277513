import Vue from 'vue'
import { HTTP } from '@/service/axios'
Vue.mixin({
	methods: {
		updateCurrent(item) {
			let data = {
				currentRound: item.currentRound,
				current: item.current
			}

			HTTP.put('/current/' + item._id, data).then(res => {
		
				if (res.data.success) {
					let update = [{
						label:'current',
						value:res.data.obj.current
					},{
						label:'currentRound',
						value:res.data.obj.currentRound
					}]
					update.map(item =>{
						this.$store.commit('SET_ROOMDATA',item)
					})
				}
			})
		}
	}
})