<template>
  <DashboardTemplate>
    <el-card class="box-card" v-loading.lock="loading">
      <h2>Assign payment term</h2>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="Team"></el-table-column>
        <el-table-column prop="payment" label="Payment term">
          <template slot-scope="scope">
            <RandomNumber :min="1" :max="10" :showNumber="false" @runningNumber="rollingNumber($event,scope.$index)" @stopNumber="submit($event,scope.row,scope.$index)" />
            <span v-show="false">{{randomNumber}}</span>
            <el-select
              v-model="scope.row.payment"
              placeholder="Payment term"
              @change="submitSelect(scope.row,scope.$index)">
              <el-option
                v-for="item in payment"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="mg-t-3">
        <el-col :span="6">
          <button class="mg-y-5 w-100 btn-primary" type="submit" @click="next()" round >Save</button>
        </el-col>
      </el-row>
    </el-card>
  </DashboardTemplate>
</template>
<script>
import { updateCurrent } from "@/service/updateCurrent";
import { HTTP } from "@/service/axios";
import DashboardTemplate from "@/template/DashboardTemplate";
import RandomNumber from "@/components/RandomNumber";
export default {
  components: {
    DashboardTemplate,
    RandomNumber
  },
  data() {
    return {
      payment: [],
      tableData: [],
      loading:false,
      randomNumber:[]
    };
  },
  computed: {
    room() {
      return this.$store.state.room;
    }
  },
  mounted() {
    this.loading = true
    this.fetchData();
  },
  methods: {
    rollingNumber(e,i){
      this.tableData[i].payment = this.payment[e-1].name
      this.randomNumber = e
    },
    async fetchData() {
      await HTTP.post("/search/room", { number: this.room.roomNumber }).then(res => {
            this.$store.commit("SET_ROOM", res.data.obj);
        }).catch(e => {
          console.log("/search/room error", e);
          this.alertFailError();
        });
      await HTTP.get("/groups/" + this.room.roomNumber).then(res => {

        if (res.data.success) {
          let data = {
            label: "groups",
            value: res.data.obj.groups
          };
          this.$store.commit("SET_ROOMDATA", data);
          this.tableData = res.data.obj.groups;
        }
      }).finally(() =>{
        setTimeout(()=>{
          this.loading = false
        },500)
      });
      await HTTP.get("/payment/term").then(res => {
        if (res.data.success) {
          this.payment = res.data.obj;
        }
      });
    },
    submitSelect(data,index) {
      let payId = this.payment.filter(se => se.name == data.payment) 

      this.tableData[index].name = this.tableData[index].name+' '
      this.tableData[index].payment = data.payment
      let item = {
        roomId: this.room._id,
        round: this.room.currentRound,
        paymenTermId: payId.length > 0 ? payId[0]._id : '',
        groupId:data._id
      };
      HTTP.put("/update/payment/term/" + data._id, item).then(res => {
        this.$socket.client.emit('getAcc',item)
      });
    },
    submit(event,data,index) {
      this.tableData[index].name = this.tableData[index].name+' '
      this.tableData[index].payment = this.payment[event-1].name
      let item = {
        roomId: this.room._id,
        round: this.room.currentRound,
        paymenTermId:this.payment[event-1]._id,
        groupId:data._id
      };
      HTTP.put("/update/payment/term/" + data._id, item).then(res => {
        this.$socket.client.emit('getAcc',item)

      });
    },
    next() {
      
      let check = 0;
      this.tableData.map(item => {
        if (typeof item.payment == "undefined") {
          check++;
        }
      });
      if (check != 0) {
        this.$message.error({
          showClose: true,
          message: "กรุณาเลือก Payment term ให้ครบทุกทีม",
          type: "error"
        });
      } else {
        this.$store.dispatch("updateCurrent", this.room);
        this.$router.push("/waiting-sale-order").catch(err => {})
      }
    }
  }
};
</script>